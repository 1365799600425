import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import session from './modules/session'
export default createStore({
  modules: {
    session
  },
  plugins: [
    createPersistedstate({
      key: 'mygct_wechat_nonbid'
    })
  ]
})
