import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant/es'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).document.title = to.meta.title === undefined ? '微信审批' : to.meta.title
  if (to.meta.login) {
    console.log(store.getters['session/login'])
    // if (!store.getters['session/login']) {
    //   next({
    //     path: '/login',
    //     query: {
    //       redirect: to.fullPath
    //     }
    //   })
    //   return
    // }
  }
  next()
})
createApp(App).use(store).use(router).use(vant).mount('#app')
