import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: {
      title: '申请保函',
      login: true
    }
  },
  {
    path: '/ok',
    name: 'ok',
    component: () => import('../views/ok.vue'),
    meta: {
      title: '订单确认',
      login: true
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue'),
    meta: {
      title: '提交成功',
      login: true
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/list.vue'),
    meta: {
      title: '订单列表',
      login: true
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue'),
    meta: {
      title: '支付信息',
      login: true
    }
  },
  {
    path: '/xieyi',
    name: 'xieyi',
    component: () => import('../views/xieyi.vue'),
    meta: {
      title: '协议列表',
      login: true
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: {
      title: '订单详情',
      login: true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue'),
    meta: {
      title: '提交成功',
      login: true
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
